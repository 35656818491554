import { logger } from '@tactiq/model';
import {
  CreateTeamMutation,
  Exact,
  TeamPreviewUpgradeInvoice,
  TeamUpdateMemberInput,
  UpdateMemberMutation,
  UpgradeToTeamInput,
  UpgradeToTeamMutation,
} from '../../graphql/operations';
import {
  createCheckoutSession_Personal,
  createCheckoutSession_Team,
} from '../../graphql/billing';
import { trackWebEvent } from '../analytics';
import { fetchApiV2 } from './helpers';
import { useMutation } from '@apollo/client';

export const upgradeToAnnualPlan = async (
  approved: boolean,
  webEventData?: Record<string, unknown>
): Promise<TeamPreviewUpgradeInvoice | undefined> => {
  trackWebEvent('Upgrade to Annual Plan', webEventData || {});
  return await fetchApiV2('/a/billing/upgrade-annual', {
    method: 'POST',
    body: JSON.stringify({
      approved,
    }),
  });
};

export const buyMultipleSeats = async (amount: number): Promise<void> => {
  return await fetchApiV2('/a/billing/buy-package', {
    method: 'POST',
    body: JSON.stringify({
      amount,
    }),
  });
};

type TeamUpdateMemberType = ReturnType<
  typeof useMutation<
    UpdateMemberMutation,
    Exact<{
      input: TeamUpdateMemberInput;
    }>
  >
>[0];

type CreateTeamType = ReturnType<
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  typeof useMutation<CreateTeamMutation, Exact<{}>>
>[0];

type UpgradeToTeamType = ReturnType<
  typeof useMutation<
    UpgradeToTeamMutation,
    Exact<{
      input: UpgradeToTeamInput;
    }>
  >
>[0];

export const upgradePlan = async (params: {
  uid: string;
  isAnnual: boolean;
  seatCount?: number;
  isTeam: boolean;
  teamId?: string;
  memberId?: string;
  isMigratingExistingSubscription?: boolean;
  teamUpdateMember?: TeamUpdateMemberType;
  stopReload?: boolean;
  createTeam: CreateTeamType;
  upgradeToTeam: UpgradeToTeamType;
  coupon?: string;
}): Promise<void> => {
  const {
    uid,
    isAnnual,
    seatCount,
    isTeam,
    teamId,
    memberId,
    isMigratingExistingSubscription = false,
    createTeam,
    upgradeToTeam,
    stopReload = false,
    coupon,
  } = params;

  trackWebEvent('Upgrade Plan', {
    userId: uid,
    isAnnual,
    seatCount,
    isTeam,
    memberId,
    isMigratingExistingSubscription,
    coupon,
    teamId,
  });

  try {
    if (isMigratingExistingSubscription) {
      if (!teamId) {
        await createTeam();
      }

      await upgradeToTeam({
        variables: {
          input: {
            toAnnual: isAnnual,
            coupon,
          },
        },
      });

      if (!stopReload) window.location.reload();
    } else {
      window.location.href = await (isTeam
        ? createCheckoutSession_Team({
            isAnnual,
            seatCount,
            memberId: memberId || uid,
            coupon,
          })
        : createCheckoutSession_Personal({ isAnnual, coupon }));
    }
  } catch (error) {
    logger.error(error);
  }
};

export const createPayPalCustomer = async (
  subscriptionId: string,
  subscriptionStatus: string,
  subscriptionPrice: number,
  nextBillingTime: string
): Promise<void> => {
  trackWebEvent('Create Paypal Customer');

  return await fetchApiV2('/a/billing/paypal-create-customer', {
    method: 'POST',
    body: JSON.stringify({
      subscriptionId,
      subscriptionStatus,
      subscriptionPrice,
      nextBillingTime,
    }),
  });
};
